import './Location.scss';
import './UpdateManyModal.scss';

import { Modal, Row, Switch, Col } from 'antd';

import { useFormik } from 'formik';
import { ListVideo } from 'lucide-react';
import React, {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import CheckInput from '../Form/CheckInput';
import { lastValueFrom, Subject } from 'rxjs';
import EditableInput from '../Form/EditableInput';
import { NoteTooltip } from '../common/NoteTooltip';
import DirectInput from '../Form/DirectInput';
const { confirm } = Modal;
import { Request } from '../../api/Request';
import getTasks from './getTasks';
import {
  validateInterval,
  validateOperatingExpenses,
  validatePrice,
} from './validation';

export default function showUpdateManyModal(ids: string[]): Promise<void> {
  const childRef = React.createRef();

  return new Promise<void>((resolve) => {
    confirm({
      icon: <ListVideo className='anticon' />,
      title: `${ids.length} Standorte bearbeiten`,
      okText: 'Speichern',
      cancelText: 'Schließen',
      content: (
        <UpdateManyModal ref={childRef} ids={ids} handleClose={resolve} />
      ),
      width: '60%',
      type: 'info',
      mask: true,
      maskClosable: false,
      onOk: () => (childRef.current as any)?.handleSubmit(),
      onCancel() {
        resolve(undefined);
      },
    });
  });
}

const UpdateManyModal = forwardRef<
  any,
  { handleClose: () => void; ids: string[] }
>((props, ref) => {
  const [callback] = React.useState(new Subject<void>());

  const [loading, setLoading] = React.useState(false);
  const [tasks, setTasks] = React.useState<any[]>([]);

  useEffect(() => {
    getTasks().then((t) => {
      setTasks(t);
      setLoading(false);
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      values: {
        labelCheckedAt: false,
        labelNextCheckedAt: false,
        locked_planing: false,
        comment: '',
        note: '',
        openingHours: '',
        area_ks: '',
        defaultTasks: {},
        oneTimeExecution: {},
        commentTasks: {},
        interval: {},
        price: {},
        operatingExpenses: {},
      },
      use: {
        labelCheckedAt: false,
        labelNextCheckedAt: false,
        locked_planing: false,
        comment: false,
        note: false,
        openingHours: false,
        area_ks: false,
        defaultTasks: {},
        oneTimeExecution: {},
        commentTasks: {},
        interval: {},
        price: {},
        operatingExpenses: {},
      },
    },
    onSubmit: async (values) => {
      const used = Object.entries(values.values).reduce((acc, [key, value]) => {
        if (values.use[key]) {
          acc[key] = value;
        }

        return acc;
      }, {});

      console.log(used);

      debugger;

      await Promise.all(
        props.ids.map((id) => {
          return Request.put('locations', id, used);
        })
      );

      callback.next();
      callback.complete();
    },
  });

  useImperativeHandle(ref, () => ({
    handleSubmit() {
      formik.handleSubmit();
      return lastValueFrom(callback);
    },
  }));

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='update-many-modal'>
      <form className=''>
        <Row className='table-divider'>
          <Col span={24}>
            <span>Label</span>
          </Col>
        </Row>
        <Row className='table-row input-row'>
          <Col span={18}>
            <CheckInput
              title='geprüft am'
              value={formik.values.values?.labelCheckedAt}
              callback={(value) => {
                formik.setFieldValue('values.labelCheckedAt', value);
                formik.setFieldValue('use.labelCheckedAt', true);
              }}
            />
          </Col>
          <Col span={6}>
            <Switch
              checked={formik.values.use.labelCheckedAt}
              title='Bearbeiten'
              onChange={(checked) => {
                formik.setFieldValue('use.labelCheckedAt', checked);
              }}
            />
          </Col>
          <Col span={18}>
            <CheckInput
              title='nächste Prüfung'
              value={formik.values.values?.labelNextCheckedAt}
              callback={(value) => {
                formik.setFieldValue('values.labelNextCheckedAt', value);
                formik.setFieldValue('use.labelNextCheckedAt', true);
              }}
            />
          </Col>
          <Col span={6}>
            <Switch
              checked={formik.values.use.labelNextCheckedAt}
              title='Bearbeiten'
              onChange={(checked) => {
                formik.setFieldValue('use.labelNextCheckedAt', checked);
              }}
            />
          </Col>
        </Row>
        <Row className='table-divider'>
          <Col span={24}>
            <span>Sperre</span>
          </Col>
        </Row>

        <Row className='table-row input-row'>
          <Col span={18}>
            <CheckInput
              title='für Planung sperren'
              value={formik.values.values?.locked_planing}
              callback={(value) => {
                formik.setFieldValue('values.locked_planing', value);
                formik.setFieldValue('use.locked_planing', true);
              }}
            />
          </Col>
          <Col span={6}>
            <Switch
              checked={formik.values.use.locked_planing}
              title='Bearbeiten'
              onChange={(checked) => {
                formik.setFieldValue('use.locked_planing', checked);
              }}
            />
          </Col>
        </Row>

        <Row className='table-divider'>
          <Col span={24}>
            <span>Sonstiges</span>
          </Col>
        </Row>
        <Row className='table-row input-row'>
          <Col span={18}>
            <DirectInput
              title='Techniker Info'
              value={formik.values.values?.comment}
              callback={(value) => {
                formik.setFieldValue('values.comment', value);
                formik.setFieldValue('use.comment', true);
              }}
            />
          </Col>
          <Col span={6}>
            <Switch
              checked={formik.values.use.comment}
              title='Bearbeiten'
              onChange={(checked) => {
                formik.setFieldValue('use.comment', checked);
              }}
            />
          </Col>
          <Col span={18}>
            <DirectInput
              title='Planer Info'
              value={formik.values.values?.note}
              callback={(value) => {
                formik.setFieldValue('values.note', value);
                formik.setFieldValue('use.note', true);
              }}
            />
          </Col>
          <Col span={6}>
            <Switch
              checked={formik.values.use.note}
              title='Bearbeiten'
              onChange={(checked) => {
                formik.setFieldValue('use.note', checked);
              }}
            />
          </Col>
          <Col span={18}>
            <DirectInput
              title='Öffnungszeiten'
              value={formik.values.values?.openingHours}
              callback={(value) => {
                formik.setFieldValue('values.openingHours', value);
                formik.setFieldValue('use.openingHours', true);
              }}
            />
          </Col>
          <Col span={6}>
            <Switch
              checked={formik.values.use.openingHours}
              title='Bearbeiten'
              onChange={(checked) => {
                formik.setFieldValue('use.openingHours', checked);
              }}
            />
          </Col>
          <Col span={18}>
            <DirectInput
              title='Area/KS'
              value={formik.values.values?.area_ks}
              callback={(value) => {
                formik.setFieldValue('values.area_ks', value);
                formik.setFieldValue('use.area_ks', true);
              }}
            />
          </Col>
          <Col span={6}>
            <Switch
              checked={formik.values.use.area_ks}
              title='Bearbeiten'
              onChange={(checked) => {
                formik.setFieldValue('use.area_ks', checked);
              }}
            />
          </Col>
        </Row>
        {tasks.map((task) => (
          <Fragment key={task.key}>
            <Row className='table-divider'>
              <Col span={24}>
                <span>{task.title}</span>
              </Col>
            </Row>

            <Row
              className='table-row input-row'
              align={'middle'}
              justify={'space-between'}
            >
              <Col span={18}>
                <CheckInput
                  title='Aktiv'
                  value={formik.values.values?.defaultTasks[task.key]}
                  callback={(value) => {
                    formik.setFieldValue(
                      `values.defaultTasks.${task.key}`,
                      value
                    );
                    formik.setFieldValue(`use.defaultTasks.${task.key}`, true);
                  }}
                />
              </Col>
              <Col span={6}>
                <Switch
                  checked={formik.values.use?.defaultTasks[task.key]}
                  title='Bearbeiten'
                  onChange={(checked) => {
                    formik.setFieldValue(
                      `use.defaultTasks.${task.key}`,
                      checked
                    );
                  }}
                />
              </Col>

              <Col span={18}>
                <CheckInput
                  title='Einmalig'
                  value={formik.values.values?.oneTimeExecution[task.key]}
                  callback={(value) => {
                    formik.setFieldValue(
                      `values.oneTimeExecution.${task.key}`,
                      value
                    );
                    formik.setFieldValue(
                      `use.oneTimeExecution.${task.key}`,
                      true
                    );
                  }}
                />
              </Col>
              <Col span={6}>
                <Switch
                  checked={formik.values.use?.oneTimeExecution[task.key]}
                  title='Bearbeiten'
                  onChange={(checked) => {
                    formik.setFieldValue(
                      `use.oneTimeExecution.${task.key}`,
                      checked
                    );
                  }}
                />
              </Col>

              <Col span={18}>
                <DirectInput
                  title='Notiz'
                  value={formik.values.values?.commentTasks[task.key]}
                  callback={(value) => {
                    formik.setFieldValue(
                      `values.commentTasks.${task.key}`,
                      value
                    );
                    formik.setFieldValue(`use.commentTasks.${task.key}`, true);
                  }}
                />
              </Col>
              <Col span={6}>
                <Switch
                  checked={formik.values.use?.commentTasks[task.key]}
                  title='Bearbeiten'
                  onChange={(checked) => {
                    formik.setFieldValue(
                      `use.commentTasks.${task.key}`,
                      checked
                    );
                  }}
                />
              </Col>

              <Col span={18}>
                <DirectInput
                  title='Intervall'
                  type='months'
                  value={formik.values.values?.interval[task.key]}
                  validate={(value: number) => validateInterval(value)}
                  callback={(value) => {
                    formik.setFieldValue(`values.interval.${task.key}`, value);
                    formik.setFieldValue(`use.interval.${task.key}`, !!value);
                  }}
                />
              </Col>
              <Col span={6}>
                <Switch
                  checked={formik.values.use?.interval[task.key]}
                  title='Bearbeiten'
                  onChange={(checked) => {
                    formik.setFieldValue(`use.interval.${task.key}`, checked);
                  }}
                />
              </Col>

              <Col span={18}>
                <DirectInput
                  title='Preis'
                  type='currency'
                  value={formik.values.values?.price[task.key]}
                  validate={(value: number) => validatePrice(value)}
                  callback={(value) => {
                    formik.setFieldValue(`values.price.${task.key}`, value);
                    formik.setFieldValue(`use.price.${task.key}`, !!value);
                  }}
                />
              </Col>
              <Col span={6}>
                <Switch
                  checked={formik.values.use?.price[task.key]}
                  title='Bearbeiten'
                  onChange={(checked) => {
                    formik.setFieldValue(`use.price.${task.key}`, checked);
                  }}
                />
              </Col>

              <Col span={18}>
                <DirectInput
                  title='AZ'
                  type='hours'
                  value={formik.values.values?.operatingExpenses[task.key]}
                  validate={(value: number) => validateOperatingExpenses(value)}
                  callback={(value) => {
                    formik.setFieldValue(
                      `values.operatingExpenses.${task.key}`,
                      value
                    );
                    formik.setFieldValue(
                      `use.operatingExpenses.${task.key}`,
                      !!value
                    );
                  }}
                />
              </Col>
              <Col span={6}>
                <Switch
                  checked={formik.values.use?.operatingExpenses[task.key]}
                  title='Bearbeiten'
                  onChange={(checked) => {
                    formik.setFieldValue(
                      `use.operatingExpenses.${task.key}`,
                      checked
                    );
                  }}
                />
              </Col>
            </Row>
          </Fragment>
        ))}
      </form>
    </div>
  );
});
